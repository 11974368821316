<template>
  <FocusTrap>
    <div id="mycard" class="card">

    <!--    @click="showFilterWindow"-->

    <div class="card-header" style="padding: 0px">
      <div class="page-header page-header-light" >
      <div class="page-header-content header-elements-lg-inline" >
        <div class="page-title d-flex" style="padding: 0px;">
          <h6>
            <i class="icon-list mr-2"></i> <span class="font-weight-semibold">Purchase </span> - List
          </h6>
          <a href="#" class="header-elements-toggle text-body d-lg-none"><i class="icon-more"></i></a>
        </div>

        <div class="header-elements d-none">
          <form action="#">
            <div class="form-group">

              <div class="input-group input-group-feedback input-group-feedback-right wmin-lg-200">
                <input id="txtsearch" type="text" class="form-control wmin-lg-200" placeholder="Search" autocomplete="off" style="padding-left: 20px;">
                <div class="form-control-feedback">
                  <i class="icon-search4"></i>
                </div>

                <div class="input-group-append position-static">
                  <button type="button" class="btn btn-outline-secondary btn-icon" @click="showFilterWindow">
                    <i class="icon-gear"></i>
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>

    <div class="card-body" style="padding: 2px;">

      <div class="table-responsive">
        <table id="invoice_list_table"
               class="table table-condensed table-columned table-hover"
               data-search="false"
               data-show-refresh="false"
               data-show-columns="false"
               data-pagination="false"
               data-page-list="[10, 25, 50, 100, ALL]"
               data-toggle="context"
               data-row-style="rowStyle"
               data-show-footer="false"
               data-row-attributes="rowAttributes"
               data-target=".context-table">
          <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-field="status" data-class="d-none">Status</th>
            <th data-field="id" data-class="d-none">Code</th>
            <th data-formatter="runningFormatter" data-width="75">S.No</th>
            <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
            <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
            <th data-field="type" data-width="100" data-formatter="transactionTypeFormatter" data-sortable="true">Type
            </th>
            <th data-field="supplier.name" data-sortable="true">Supplier</th>
            <!--<th data-field="ldgr.state_cd" data-sortable="true">State</th>-->
            <th data-field="ref_no" data-width="100" data-sortable="true">Inv No</th>
            <th data-field="ref_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Inv Date</th>
            <th data-field="net_amt" data-width="100" data-align="right" data-formatter="indianFormat"
                data-sortable="true">Amount
            </th>
            <!--            <th data-field="acc_code" data-sortable="true">Acc Code</th>-->
            <th data-field="remarks" data-sortable="true">Remarks</th>
          </tr>
          </thead>
          <tbody>

          </tbody>
          <tfoot>
          </tfoot>
        </table>
      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table context-submenu">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset"
                                                                 style="color: darkgreen"></i>Refresh</a>
          <a href="#" class="dropdown-item"><i class="icon-file-eye2"></i>Preview</a>

          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" style="color: blue;"> <i class="icon-pencil3"></i>Modify Purchase</a>
<!--          <div class="dropdown-divider"></div>-->
<!--          <a href="#" class="dropdown-item"> <i class="icon-move"></i>Move</a>-->
<!--          <a href="#" class="dropdown-item" style="color: red"> <i class="icon-blocked"></i>Cancel</a>-->

          <!--<div class="dropdown-divider"></div>-->
          <!--<div class="dropdown-submenu">-->
          <!--<a href="#" class="dropdown-item dropdown-toggle">More options</a>-->
          <!--<div class="dropdown-menu">-->
          <!--<a href="#" class="dropdown-item">Second level link</a>-->
          <!--<a href="#" class="dropdown-item">Second level link</a>-->
          <!--<a href="#" class="dropdown-item">Second level link</a>-->
          <!--</div>-->
          <!--</div>-->


        </div>
      </div>
      <!-- End of Context Menu -->

      <FocusTrap>
        <vmodal name="invoice-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="90%" height="80%" @before-open="beforeOpen" @before-close="beforeClose">
          <InvoiceForm v-bind:myvoucher="invoice" v-on:purchase_saved="closeModal"  v-on:purchase_window_closed="closeModal" />
        </vmodal>
      </FocusTrap>

      <FocusTrap>
        <div class="modal" id="payment_modal">
          <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">

              <!-- Modal body -->
              <div class="modal-body">
                <PaymentForm v-if="invoice.id  > 1" v-bind:invoice="invoice" v-on:payment_updated="onPaymentUpdate"></PaymentForm>
              </div>


            </div>
          </div>
        </div>
      </FocusTrap>

      <div class="modal" id="previewmodal">
        <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">
              <PurchaseInvoicePreview v-if="invoice.id  > 1" v-bind:invoice="invoice"></PurchaseInvoicePreview>
            </div>


          </div>
        </div>
      </div>

      <div class="modal" id="voucherpreview_modal">
        <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body">
              <VoucherPreviewForm v-if="voucher.id  > 1" v-bind:myvoucher="voucher"></VoucherPreviewForm>
            </div>


          </div>
        </div>
      </div>

      <vmodal name="filter-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="300" @opened="afterfilterwindow_visible">
        <form>
          <div class="card">
            <div class="card-header header-elements-inline" style="padding: 10px;background-color: whitesmoke!important;">
              <h3 class="card-title">Filter</h3>
              <div class="header-elements">
                <div class="list-icons">
                  <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                </div>
              </div>
            </div>

            <div class="card-body">

              <p/>

              <div class="form-group row">
                <label class="col-md-3 col-form-label">From:</label>
                <div class="col-md-3">
                  <input id="txtfrom" type="date" class="form-control" v-model="from_date" :max="upto_date"/>
                </div>

                <label class="col-md-3 col-form-label">Upto</label>
                <div class="col-md-3">
                  <input type="date" class="form-control" v-model="upto_date" :min="from_date"/>
                </div>
              </div>


              <div class="form-group row">
                <label class="col-md-3 col-form-label">Purchase Type:</label>
                <div class="col-md-9">
                  <select id="cmbtypes" class="form-control" v-model="purchtype">
                    <option value="0" selected>All</option>
                    <option value="301">B2B</option>
                    <option value="302">B2C</option>
                    <option value="303">Composistion</option>
                    <option value="304">Exempted</option>
                    <option value="305">Imported</option>
                    <option value="306">Non GST</option>
                    <option value="307">In Eligible</option>
                    <option value="308">RCM</option>

                    <option value="300">Others</option>
                  </select>
                </div>
              </div>


              <div class="form-group row">
                <label class="col-md-3 col-form-label">Supplier:</label>
                <div class="col-md-9">
                  <select class="form-control" v-model="supplier_id">
                    <option value="0" selected>All</option>
                    <option v-for="ledger in ledgers" v-bind:value="ledger.id ">
                      {{ ledger.name }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="text-right">
                <button type="button"
                        class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple"
                        @click="loadData">Show Data<i class="icon-database-refresh ml-2"></i></button>
              </div>

            </div>
          </div>
        </form>
      </vmodal>

      <vmodal name="ibr-move-window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="250"
              height="300">
        <!-- Form -->
        <form class="modal-body form-validate" action="#">
          <div class="text-center mb-3">
            <i class="icon-reading icon-2x text-slate-300 border-slate-300 border-3 rounded-round p-3 mb-3 mt-1"></i>
            <h5 class="mb-0">Choose Branch to be Move</h5>
          </div>

          <div class="form-group form-group-feedback form-group-feedback-left">
            <select class="form-control" v-model="selected_ibr">
              <option v-for="brn in branches" v-bind:value="brn.id ">
                {{ brn.name }}
              </option>
            </select>
            <div class="form-control-feedback">
              <i class="icon-box text-muted"></i>
            </div>
          </div>


          <div class="form-group">
            <button type="button"
                    class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple btn-block"
                    @click="moveToIBR">Move <i class="icon-move-right ml-2"></i></button>
          </div>

        </form>
        <!-- /form -->

      </vmodal>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import InvoiceForm from '@/views/inventory/purchase/InvoiceForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'InvoiceView',
    components: {
      InvoiceForm,
      // PaymentForm,
      // PurchaseInvoicePreview,
      // VoucherPreviewForm,
      FabButton
    },
    store,
    data () {
      return {
        mytable: {},
        voucher: JSON.parse('{"id":"","com_id":"","type":0,"status":0,"finyear":0,"vchtype":0,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":2,"status":0,"type":0,"name":"","alias":"","print_name":"","group":{"id":"","code":0,"status":0,"type":0,"name":"","alias":"","print_name":"","parent_code":0,"nature":0,"affect_profit":false,"behave_like_sub_ledger":false,"net_cr_dr_balance":false,"used_for_calculation":false,"system_object":false},"opening_balance":0,"print_order":0,"gstin":"","address":{"name":"","street":"","city":"","pincode":"","state_code":0,"email":"","telephone":"","mobile":""},"system_object":false},"amount":0,"remarks":"","details":[]}'),
        invoice: JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}'),
        series: 302,
        from_date: moment().format('YYYY-MM-DD'),
        upto_date: moment().format('YYYY-MM-DD'),
        purchtype: 0,
        purchfrom: 0,
        branches: [],
        invoice_id: '',
        selected_ibr: 1,
        ledgers: [],
        supplier_id: 0,
      }
    },
    created () {

    },
    mounted () {
      const self = this;

      self.$data.from_date = moment().add(-7, 'd').format('YYYY-MM-DD');

      this.$data.mytable = $('#invoice_list_table');
      this.$data.mytable.bootstrapTable();
      $('#invoice_list_table').on('post-body.bs.table', function (e) {
        $('[data-toggle="popover"]').popover();
      });

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {

          const id = $(row.children('*')[0]).text();
          const status = parseInt($(row.children('*')[1]).text());

          if ($(e.target).text() === 'Modify Purchase') {
            // if (status === 0) {
            self.modifyDocument(id)
            // }
          } else if ($(e.target).text() === 'Modify Payment') {
            if (status >= 0) {
              self.modifyPayment(id, status)
            }
          } else if ($(e.target).text() === 'Cancel') {
            // if(status === 0) {
            self.removeDocument(id, status)
            //}
          } else if ($(e.target).text() == 'Preview') {
            self.invoicePreview(id)
          }
        }
      })

      $('#txtsearch').keyup(function () {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#invoice_list_table>tbody>tr').show().filter(function () {
          let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
          return !~text.indexOf(val);
        }).hide();
      });

      // self.loadData();
      self.loadSuppliers();

      $('.context-submenu').contextmenu({
        target: '.dropdown-menu'
      }).on('hidden.bs.context', function () {
        $(this).find('.context-sub-menu .show').removeClass('show')
      });

      self.showFilterWindow ();

    },
    methods: {
      afterfilterwindow_visible(){
        $('#txtfrom').focus();
      },
      showFilterWindow () {
        this.$modal.show('filter-window');
      },
      closeModal () {
        this.$modal.hide('invoice-window')
      },
      beforeOpen () {

      },
      beforeClose () {
        // this.$data.invoice = JSON.parse('{"id":"","code":0,"ver":0,"status":0,"is_cr":"Y","type":0,"finyear":2018,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","ledger":{"id":"","code":0,"type":0,"group_code":0,"name":"","print_name":"","street":"","city":"","pin":"","state_id":0,"gstin":"","contact_person":"","telephone":"","email":""},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"remarks":"","gross_amount":0,"tax_amount":0,"round_off":0,"net_amount":0,"list":[]}');
      },
      showModal () {
        this.$modal.show('invoice-window')
      },
      blockModal () {
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })
      },
      onPaymentUpdate () {
        this.loadData()
        $('#payment_modal').modal('hide')
      },
      invoicePreview (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"net_amt":0,"list":[]}');
        fetch(`${process.env.VUE_APP_ROOT_API}api/purchase/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {

            self.$data.invoice = resp.data
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')
            $('#previewmodal').modal('show')

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        })

      },
      loadSuppliers () {
        const self = this;

        const requestOptions = {
          mode: 'cors',
          headers: userService.authHeader()
        }

        //fetch the Ledgers
        self.$data.ledgers = []
        fetch(`${process.env.VUE_APP_ROOT_API}api/suppliers`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (_.isArray(resp.data)) {
              self.$data.ledgers = resp.data
            }
          } else {
            swal({ title: 'Oops', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oops', text: err.toString(), type: 'error' })
        })
      },
      exportInvoice (id) {
        const self = this;

        const requestOptions = {
          method: 'POST',
          mode: 'cors',
          headers: userService.authHeader()
        }

        self.blockModal()
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/export/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Good!', text: resp.msg, type: 'success' })
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
        }).finally(function () {
          $('#mycard').unblock()
        })
      },
      invoicePayment (id, status) {
        const self = this;

        if (status == 0) {
          const requestOptions = {
            method: 'GET',
            mode: 'cors',
            headers: userService.authHeader()
          }

          self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"net_amt":0,"list":[]}');
          fetch(`${process.env.VUE_APP_ROOT_API}api/purchase/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {

              self.$data.invoice = resp.data;
              self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
              self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');

              $('#payment_modal').modal('show')

            } else {
              swal({ title: 'Oops!', text: resp.msg, type: 'error' });
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
          })
        }
      },
      modifyDocument (id) {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }
        self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"brn_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"tcs_amt":0,"net_amt":0,"list":[]}');

        fetch(`${process.env.VUE_APP_ROOT_API}api/purchase/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {

            self.$data.invoice = resp.data;
            self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD');
            self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD');
            self.$modal.show('invoice-window');
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        })
      },
      modifyPayment (id, status) {
        const self = this;

        if (status > 0) {

          const requestOptions = {
            method: 'GET',
            mode: 'cors',
            headers: userService.authHeader()
          }

          self.$data.invoice = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":1,"finyear":2000,"series":302,"doc_date":"0001-01-01","doc_no":0,"ref_no":"","ref_date":"0001-01-01","process_id":1,"supplier_id":1,"supplier":{"id":0,"type":0,"status":"Active","name":"","street":"","city":"","pin":"","state_cd":33,"tel":"","mobile":"","email":"","web":"","gstin":"","open_bal":0},"eway_no":"","eway_date":"0001-01-01","vehicle_no":"","process":{"id":0,"name":"","nature":0},"remarks":"","disc_amt":0,"gross_amt":0,"igst_amt":0,"cgst_amt":0,"sgst_amt":0,"round_off":0,"tds_amt":0,"net_amt":0,"list":[]}');
          fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/transaction/${id}/`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {

              self.$data.invoice = resp.data
              self.$data.invoice.doc_date = moment(resp.data.doc_date).format('YYYY-MM-DD')
              self.$data.invoice.ref_date = moment(resp.data.ref_date).format('YYYY-MM-DD')

              self.$data.invoice.payment.pay_date = moment(resp.data.payment.pay_date).format('YYYY-MM-DD')
              self.$data.invoice.payment.clearing_date = moment(resp.data.payment.clearing_date).format('YYYY-MM-DD')

              $('#payment_modal').modal('show')

            } else {
              swal({ title: 'Oops!', text: resp.msg, type: 'error' })
            }
          }).catch(function (err) {
            swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
          })
        }

      },
      removeDocument (id) {
        const self = this;

        self.$data.invoice.id = id

        const requestOptions = {
          method: 'DELETE',
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.invoice)
        }

        swal({
          title: 'What is the reason for cancel this transaction?',
          input: 'text',
          inputPlaceholder: 'Enter Remarks here',
          showCancelButton: true,
          inputClass: 'form-control',
          inputValidator: function (value) {
            return !value && 'You need to write something!'
          }
        }).then(function (result) {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}api/purchase?remarks=${result.value}`, requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('receipt-window')
              if (resp.ok) {
                self.loadData()
                swal({
                  title: 'Canceled!',
                  text: 'Your request has been processed',
                  type: 'success'
                })
              } else {
                swal({ title: 'Oops', text: resp.msg, type: 'info' })
              }

            }).catch(function (err) {
              swal({ title: 'Oops', text: err.toString(), type: 'error' })
            })
          }
        })

      },
      loadData () {
        const self = this;

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#txtsearch').val('');

        $(self.$data.mytable).block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        self.$data.mytable.bootstrapTable('load', []);
        self.$data.mytable.bootstrapTable('showLoading');


        fetch(`${process.env.VUE_APP_ROOT_API}api/purchases?ldgr=${self.$data.supplier_id}&from=${self.$data.from_date}&upto=${self.$data.upto_date}&types=${self.$data.purchtype}&purchfrom=${self.$data.purchfrom}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            if (!_.isNull(resp.data)) {
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error' });
          }
          self.$modal.hide('filter-window')
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error' });
        }).finally(function () {
          self.$data.mytable.bootstrapTable('hideLoading');
          $(self.$data.mytable).unblock();
          // $(self.$data.mytable).bootstrapTable('resetView');
        })
      },
    }

  }
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00!important;
  color: #0a0a0a!important;
}
</style>

